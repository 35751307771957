import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import Image from 'next/image'
import { FC, Fragment, useCallback } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import NotePencilIcon from '~/src/components/generic/PhosphorIcons/NotePencilIcon'
import QuestionIcon from '~/src/components/generic/PhosphorIcons/QuestionIcon'

import { useSupportFormEmbed } from '~/src/components/shared/NavBar/hooks/useSupportForm'
import LightCircleQuestion from '~/src/components/shared/NavBar/img/LightCircleQuestion.svg'

import { isRadiusUser } from '~/helpers/auth'

import { useAuthentication } from '~/hooks/useAuthentication'

import colors from '~/config/colorsScheme'

type Props = {
  onProvideFeedbackClicked: () => void
}

const SupportMenu: FC<Props> = ({ onProvideFeedbackClicked }) => {
  const [user] = useAuthentication()

  const useModalFormEmbed = !isRadiusUser(user)

  const supportFormUrl = process.env.NEXT_PUBLIC_SERVICENOW_CVT_URL

  const { modal, openModal } = useSupportFormEmbed({
    supportFormUrl,
  })

  const { useEmbeddedSupportForm } = useFlags()

  const handleRequestHelp = useCallback(() => {
    if (useModalFormEmbed) {
      if (useEmbeddedSupportForm) {
        openModal()
      } else {
        window.open(supportFormUrl, '_blank')
      }
    } else {
      window.open(process.env.NEXT_PUBLIC_SERVICENOW_RADIUS_URL, '_blank')
    }
  }, [openModal, useModalFormEmbed, supportFormUrl, useEmbeddedSupportForm])

  return (
    <>
      {modal}
      <Menu>
        <MenuButton className="rounded p-2 hover:bg-gray-50">
          <div className="relative">
            <Image
              src={LightCircleQuestion}
              alt="Question mark in circle icon"
              width={24}
              height={24}
            />
          </div>
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="flex w-52 flex-col items-start rounded bg-white shadow-full"
          >
            <MenuItem key="item1">
              <button
                className="flex w-full flex-row items-center gap-x-4 px-4 pb-3 pt-4 data-[focus]:bg-gray-50"
                onClick={handleRequestHelp}
              >
                <QuestionIcon size="22" color={colors.gray[500]} />
                <div>Request help</div>
              </button>
            </MenuItem>
            <MenuItem key="item2">
              <button
                className="flex w-full flex-row items-center gap-x-4 px-4 pb-4 pt-3 data-[focus]:bg-gray-50"
                onClick={onProvideFeedbackClicked}
              >
                <NotePencilIcon size="22" color={colors.gray[500]} />
                <div>Provide feedback</div>
              </button>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </>
  )
}

export default SupportMenu
