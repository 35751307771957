import { Dialog, DialogPanel } from '@headlessui/react'
import { useCallback, useMemo, useState } from 'react'

import Spinner from '~/src/components/generic/Spinner'

export const useSupportFormEmbed = ({
  supportFormUrl,
}: {
  supportFormUrl: string | undefined
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    supportFormUrl && setIsOpen(true)
  }, [supportFormUrl])

  const closeModal = useCallback(() => setIsOpen(false), [])

  const modal = useMemo(
    () => (
      <Dialog
        open={isOpen}
        onClose={closeModal}
        className="bg-grey-900 relative z-10 flex h-screen w-screen items-center justify-center bg-opacity-60"
      >
        <div className="fixed left-0 top-0 z-[999] h-screen w-screen bg-black opacity-25" />
        <div className="fixed inset-0 z-[999] flex min-h-full items-center justify-center overflow-y-auto">
          <DialogPanel className="h-[calc(80vh)] max-h-[660px] min-h-[600px] w-[500px] overflow-hidden rounded-xl bg-white align-middle shadow-xl max-w-500:w-[340px] max-h-600:h-[466px]">
            <Spinner
              className="absolute left-1/2 top-1/2 z-0"
              style={{ translate: '-50% -50%' }}
            />
            <iframe
              className="relative h-full w-full rounded-xl"
              src={supportFormUrl}
              title="Rydershare Support Form"
            />
          </DialogPanel>
        </div>
      </Dialog>
    ),
    [supportFormUrl, isOpen, closeModal]
  )

  return {
    modal,
    modalIsOpen: isOpen,
    openModal,
    closeModal,
  }
}
