import { Color } from '~/src/components/shared/Notification/types'

export const NotificationDisplayTier = {
  RISK: 'AT RISK',
  CRITICAL: 'CRITICAL',
  RESOLVED: 'RESOLVED',
}

export const NotificationDisplayViolation = {
  HOS_VIOLATION: 'HoS violation',
  DETENTION: 'Two-hour dwell',
  LOAD_NOT_STARTED: 'Load not started',
  LOAD_AUDIT_DUE: 'Load audit due',
  LATE_APPOINTMENT: 'Late appointment',
}

export const colorsMapping: { [key: string]: Color } = {
  CRITICAL: 'red',
  RISK: 'yellow',
}

export const NOTIFICATION_COLOR_CLASS_NAME = {
  red: 'bg-red-50 rounded-sm w-fit px-0.5',
  yellow: 'bg-yellow-50 rounded-sm w-fit px-0.5',
}
