import { ReactNode, PropsWithChildren as PWC, forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ArrowPlacement, Placement } from '~/src/components/generic/Tooltip/types'
import {
  getTooltipContainerClass,
  getTooltipArrowClass,
} from '~/src/components/generic/Tooltip/helpers'

type Props = {
  hide?: boolean
  className?: string
  content: ReactNode
  placement?: Placement
  arrowPlacement?: ArrowPlacement
}

const Tooltip = forwardRef<HTMLDivElement, PWC<Props>>(
  (
    {
      children,
      content,
      className,
      placement = 'top',
      hide = false,
      arrowPlacement = 'left',
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false)

    if (hide) {
      return <div>{children}</div>
    }

    const handleTouchStart = () => {
      setVisible(true)
    }

    const handleTouchEnd = () => {
      setTimeout(() => {
        setVisible(false)
      }, 3000) // Hide the tooltip after 3 seconds on mobile devices
    }

    const handleMouseEnter = () => {
      setVisible(true)
    }

    const handleMouseLeave = () => {
      setVisible(false)
    }

    const showTooltip = () => setVisible(true)
    const hideTooltip = () => setVisible(false)

    return (
      <div
        className="relative"
        ref={ref}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={showTooltip}
        onBlur={hideTooltip}
        role="tooltip"
      >
        <div aria-describedby="tooltip-content">{children}</div>
        <div
          data-testid="tooltip-content"
          id="tooltip-content"
          aria-hidden={!visible}
          className={twMerge(
            'absolute z-50 min-w-24 max-w-96 rounded bg-gray-700 p-2 text-xs !text-white',
            visible ? 'scale-100' : 'scale-0',
            getTooltipContainerClass(placement),
            className
          )}
        >
          {content}
          <div
            className={twMerge(
              'absolute',
              getTooltipArrowClass(placement, arrowPlacement)
            )}
          ></div>
        </div>
      </div>
    )
  }
)

Tooltip.displayName = 'Tooltip'
export default Tooltip
