import {
  useState,
  FocusEvent,
  useRef,
  ReactNode,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  ChangeEvent,
} from 'react'
import Fuse from 'fuse.js'
import { twMerge } from 'tailwind-merge'

import Results from '~/src/components/generic/SearchableDropdownV2/components/Results'
import SearchIcon from '~/src/components/generic/SearchableDropdownV2/components/SearchIcon'
import Input from '~/src/components/generic/Input'

import useOnClickOutside from '~/hooks/useOnClickOutside'
import useHotKeys from '~/hooks/useHotKeys'

const DELAY = 300

const getRelevantOptions = <T,>({
  options,
  searchKeys,
  searchValue,
  threshold,
}: {
  options: Array<T>
  searchKeys: Array<string>
  searchValue: string
  threshold: number
}): Array<T> => {
  return new Fuse(options, searchKeys ? { keys: searchKeys, threshold: threshold } : {})
    .search(searchValue)
    .map(({ item }) => item)
}

export type Props<T> = {
  isLoading?: boolean
  className?: string
  inputClassName?: string
  value: string
  options: Array<T>
  placeholder?: string
  searchKeys?: Array<string>
  fuseThreshold?: number
  maxNumOptions: number
  showSearchIcon?: boolean
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (value: string) => void
  onSelect: (value: T) => void
  renderOption: (value: T) => ReactNode
  onCloseHandler?: () => void
  searchCallback?: (value: string) => void
  resultsClassName?: string
  autoFocus?: boolean
  id?: string
}

/**
 * Simplify version of SearchableDropdown as there are few properties not used in the component
 * @param className - Tailwind classes to be applied to the outermost div
 * @param inputClassName - Tailwind classes to be applied to the input
 * @param value - The value of the input
 * @param options - The options to be displayed in the dropdown
 * @param placeholder - The placeholder text for the input
 * @param searchKeys - The keys to be searched on
 * @param fuseThreshold - The threshold for the fuse search
 * @param maxNumOptions - The maximum number of options to be displayed
 * @param showSearchIcon - Whether or not to show the search icon
 * @param onFocus - The function to be called when the input is focused
 * @param onBlur - The function to be called when the input is blurred
 * @param onChange - The function to be called when the input value changes
 * @param onSelect - The function to be called when an option is selected
 * @param renderOption - The function to be called when rendering an option
 * @param onCloseHandler - The function to be called when the dropdown is closed
 * @param searchCallback - The function to be called when searching
 * @param autoFocus - Whether or not to autofocus the input
 * @param id - The id of the input
 *
 * @example
 * <SearchableDropdownV2
 *  className="w-1/2"
 *  inputClassName="w-full"
 *  value={value}
 *  options={options}
 *  placeholder="Search"
 *  searchKeys={['id', 'name']}
 *  fuseThreshold={0.2}
 *  maxNumOptions={100}
 *  showSearchIcon={true}
 *  onFocus={() => console.log('Focused')}
 *  onBlur={() => console.log('Blurred')}
 *  onChange={(value) => console.log('Changed', value)}
 *  onSelect={(value) => console.log('Selected', value)}
 *  renderOption={(value) => <div>{value}</div>}
 *  onCloseHandler={() => console.log('Closed')}
 *  id="search"
 * />
 */
const SearchableDropdownV2 = <T,>({
  isLoading,
  inputClassName,
  className,
  value = '',
  options,
  placeholder,
  searchKeys,
  fuseThreshold = 0.2,
  maxNumOptions,
  showSearchIcon = true,
  autoFocus = false,
  renderOption,
  onFocus,
  onBlur,
  onChange,
  onSelect,
  onCloseHandler,
  searchCallback,
  resultsClassName,
  id,
}: Props<T>) => {
  const [searchString, setSearchString] = useState(value)
  const [highlightedRowIndex, setHighlightedRowIndex] = useState(-1)
  const [hasFocus, setHasFocus] = useState(autoFocus)

  const inputEl = useRef<HTMLInputElement | null>(null)

  useHotKeys('a', () => setTimeout(() => inputEl.current?.focus()), {
    description: 'Select the account search box',
    ctx: 'visibility',
  })

  useEffect(() => {
    setSearchString(value)
  }, [value])

  useOnClickOutside(inputEl, () => setHighlightedRowIndex(-1))

  useEffect(() => {
    const debouncedGetSearchResults = setTimeout(() => {
      searchCallback?.(searchString)
    }, DELAY)

    return () => clearTimeout(debouncedGetSearchResults)
  }, [searchString, searchCallback])

  const handleOnFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onFocus?.(event)
      setHasFocus(true)
    },
    [onFocus]
  )

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event)
      !showSearchIcon && setSearchString('')
      setHasFocus(false)
    },
    [onBlur, showSearchIcon]
  )

  const renderOptions = useMemo(() => {
    if (searchCallback || searchString === '') {
      return options
    } else {
      return (
        (searchKeys &&
          getRelevantOptions({
            options,
            searchKeys: searchKeys,
            searchValue: searchString,
            threshold: fuseThreshold,
          })) ??
        []
      )
    }
  }, [options, searchCallback, searchString, fuseThreshold, searchKeys])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<Element>): void => {
      if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(event.key)) {
        event.preventDefault()
      }

      let itemIndex: number

      switch (event.key) {
        case 'ArrowUp':
          itemIndex = Math.max(0, highlightedRowIndex - 1)
          setHighlightedRowIndex(itemIndex)
          break
        case 'ArrowDown':
          const leftBound = Math.min(maxNumOptions, renderOptions.length)
          itemIndex = Math.min(leftBound - 1, highlightedRowIndex + 1)
          setHighlightedRowIndex(itemIndex)
          break
        case 'Enter':
          if (renderOptions?.[highlightedRowIndex]) {
            onSelect(renderOptions[highlightedRowIndex])
          }

          inputEl.current?.blur()
          setHighlightedRowIndex(-1)
          break
        case 'Escape':
          setHighlightedRowIndex(-1)
          inputEl.current?.blur()
          break
        default:
          break
      }
    },
    [highlightedRowIndex, maxNumOptions, onSelect, renderOptions]
  )

  const handleSetSearchString = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchString(event.target.value)
      onChange?.(event.target.value)
    },
    [onChange]
  )

  const handleClearSearchString = useCallback(() => {
    setHasFocus(true)
    setHighlightedRowIndex(-1)
    setSearchString('')
    onCloseHandler?.()
    inputEl.current?.focus()
  }, [onCloseHandler])

  return (
    <div
      data-testid="search-autocomplete"
      className={twMerge('group relative', className)}
    >
      <Input
        className={inputClassName}
        ref={inputEl}
        autoFocus={autoFocus}
        value={searchString}
        placeholder={placeholder}
        onChange={handleSetSearchString}
        onFocus={handleOnFocus}
        onKeyDown={(event) => handleKeyPress(event)}
        onBlur={handleOnBlur}
        id={id}
      />
      <SearchIcon
        showSearchIcon={showSearchIcon}
        searchString={searchString}
        inputEl={inputEl}
        handleClearSearchString={handleClearSearchString}
      />
      <Results
        maxNumOptions={maxNumOptions}
        isLoading={isLoading}
        hasFocus={hasFocus}
        results={renderOptions}
        highlightedRowIndex={highlightedRowIndex}
        searchString={searchString}
        renderOption={renderOption}
        setHighlightedRowIndex={setHighlightedRowIndex}
        onSelect={onSelect}
        resultsClassName={resultsClassName}
      />
    </div>
  )
}

export default SearchableDropdownV2
