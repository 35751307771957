import { useFlags } from 'launchdarkly-react-client-sdk'
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import {
  Notification,
  NotificationProps,
} from '~/src/components/shared/Notification/types'

import { NotificationType } from '~/__generated_types__/globalTypes'

export type NotificationContextType = {
  notifications: Array<Notification>
  update: (messages: Array<Notification>) => void
  isDropdownOpen: boolean
  setIsDropdownOpen: (isOpen: boolean) => void
}

export const NotificationContext = createContext<NotificationContextType | null>(null)

export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Array<NotificationProps>>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const { allowLateReasonForRv3 } = useFlags()

  const update = useCallback(
    (messages: Array<Notification>) => {
      let filteredMessages = messages

      if (!allowLateReasonForRv3) {
        filteredMessages = messages.filter(
          (notification) => notification.type !== NotificationType.LateAppointment
        )
      }

      setNotifications(filteredMessages)
    },
    [allowLateReasonForRv3]
  )

  const value: NotificationContextType = useMemo(
    () => ({
      update,
      notifications,
      isDropdownOpen,
      setIsDropdownOpen,
    }),
    [update, notifications, isDropdownOpen, setIsDropdownOpen]
  )

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
