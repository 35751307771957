import { ReactNode } from 'react'

import CheckIcon from '~/src/components/generic/PhosphorIcons/CheckIcon'
import ProhibitIcon from '~/src/components/generic/PhosphorIcons/ProhibitIcon'

import { getWeekDateRange } from '~/src/components/Operational/LoadBoardDashboard/helpers'

import colors from '~/config/colorsScheme'

import {
  BackhaulEligibleCode,
  LegStatus,
  LoadStatus,
  SortInput,
  TractorStatus,
} from '~/__generated_types__/globalTypes'

import { Option } from '~/src/components/Operational/LoadBoardDashboard/DetailView/components/EmptyMileSelectDropdown/types'
import { StopStatus } from '~/src/components/Operational/LoadBoardDashboard/types'

export const ORDER_STATUSES: Array<LoadStatus> = [
  LoadStatus.Available,
  LoadStatus.Planned,
  LoadStatus.Dispatched,
  LoadStatus.Started,
  LoadStatus.Suspended,
  LoadStatus.Imported,
  LoadStatus.Completed,
  LoadStatus.Cancelled,
  LoadStatus.InAudit,
]

export const ASSIGNABLE_LEG_STATUS = [
  LegStatus.Planned,
  LegStatus.Available,
  LegStatus.Dispatched,
]

export const StopStatusMap: Record<StopStatus, string> = {
  [StopStatus.Completed]: 'Completed',
  [StopStatus.AtLocation]: 'At Location',
  [StopStatus.InTransit]: 'In Transit',
  [StopStatus.Planned]: 'Planned',
  [StopStatus.Cancelled]: 'Cancelled',
  [StopStatus.Skipped]: 'Skipped',
}

export enum SortPlannedStartTime {
  PlannedStartTimeAsc = 'PLANNED_START_TIME_ASC',
  PlannedStartTimeDesc = 'PLANNED_START_TIME_DESC',
}

export const LIMIT = 25

export const EMPTY_FILTER = {
  costCenterIdEq: null,
  costCenterIdIn: null,
  driverIdIn: null,
  routeIdIn: null,
  locationIdIn: null,
  idEq: null,
  referenceValueEq: null,
  limit: LIMIT,
  offset: 0,
  statusIn: [...ORDER_STATUSES],
  plannedStartTimeDateRange: [
    getWeekDateRange().start ?? '',
    getWeekDateRange().end ?? '',
  ],
  sortByPlannedStartTime: SortInput.Asc,
}

export const STOP_STATUS_COLOR_MAP: Map<StopStatus, string> = new Map([
  [StopStatus.Completed, 'bg-blue-50 text-blue-800'],
  [StopStatus.AtLocation, 'bg-green-50 text-green-800'],
  [StopStatus.InTransit, 'bg-green-50 text-green-800'],
  [StopStatus.Planned, 'bg-yellow-50 text-yellow-900'],
  [StopStatus.Cancelled, 'bg-gray-50 text-gray-800'],
  [StopStatus.Skipped, 'bg-gray-50 text-gray-800'],
])

export const ERROR_DELAY = 15000
export const SUCCESS_DELAY = 5000

export const DETAIL_TABLE_HEADER: Record<
  string,
  { name: ReactNode; widthPct: number; className?: string }
> = {
  idx: { name: '', widthPct: 2, className: 'text-center' },
  location: { name: 'Location', widthPct: 16 },
  stopReferences: { name: 'Stop refs', widthPct: 8 },
  criticalApp: { name: 'Critical', widthPct: 5 },
  app: { name: 'Appointment', widthPct: 15 },
  inout: {
    name: 'In/Out',
    widthPct: 15,
  },
  miles: { name: 'Miles', widthPct: 6 },
  transit: {
    name: (
      <div className="flex flex-col space-y-0.5">
        <span>Transit/</span>
        <span>Dwell</span>
      </div>
    ),
    widthPct: 7,
  },
  stopEvent: { name: 'Event', widthPct: 7 },
  type: { name: 'Stop Type', widthPct: 7 },
  mileageType: { name: 'Mile Type', widthPct: 7 },
  odometer: { name: 'ODO', widthPct: 7 },
  status: { name: 'Status', widthPct: 5 },
  menu: { name: '', widthPct: 4 },
}

export const DETAIL_TABLE_HEADER_V2: Record<
  string,
  { name: ReactNode; widthPct: number; className?: string }
> = {
  idx: { name: '', widthPct: 2, className: 'text-center' },
  location: { name: 'Location', widthPct: 16 },
  app: { name: 'Appointment', widthPct: 16 },
  inout: {
    name: 'In/Out',
    widthPct: 16,
  },
  miles: { name: 'Miles', widthPct: 6 },
  transit: {
    name: (
      <div className="flex flex-col space-y-0.5">
        <span>Transit/</span>
        <span>Dwell</span>
      </div>
    ),
    widthPct: 7,
  },
  stopEvent: { name: 'Event', widthPct: 7 },
  type: { name: 'Stop Type', widthPct: 7 },
  mileageType: { name: 'Mile Type', widthPct: 7 },
  odometer: { name: 'ODO', widthPct: 7 },
  status: { name: 'Status', widthPct: 5 },
  menu: { name: '', widthPct: 4 },
}

const InvalidTMWVersionIDScos = 'InvalidTMWVersionIDScos'
const InvalidTMWVersionIDClient = 'InvalidTMWVersionIDClient'

export const TMW_VERSION_ID_ERRORS = [
  InvalidTMWVersionIDScos,
  InvalidTMWVersionIDClient,
]

// Not editable statuses for empty mile eligibility
export const NON_EDITABLE_LOAD_STATUSES = new Set([
  LoadStatus.Suspended,
  LoadStatus.Imported,
  LoadStatus.Completed,
  LoadStatus.Cancelled,
])

// List of statuses that are editable
export const EDITABLE_LOAD_STATUSES: Array<LoadStatus> = [
  LoadStatus.Available,
  LoadStatus.Planned,
  LoadStatus.Dispatched,
  LoadStatus.Started,
  LoadStatus.InAudit,
]

// This need to be fixed, constants file should not have any JSX
export const EMPTY_MILE_ELIGIBILITY_OPTIONS: Array<Option> = [
  {
    value: 'Eligible - Backhaul',
    description: 'Need a 3rd party load to add to trip',
    icon: <CheckIcon size={16} weight="bold" color={colors.green[600]} />,
    code: BackhaulEligibleCode.Yes,
  },
  {
    value: 'Eligible - Headhaul',
    description: 'Need a 3rd party load standalone trip',
    icon: <CheckIcon size={16} weight="bold" color={colors.green[600]} />,
    code: BackhaulEligibleCode.Yeshh,
  },
  {
    value: 'Eligible - Pre-booked',
    description: 'Has already booked with Emp team',
    icon: <CheckIcon size={16} weight="bold" color={colors.green[600]} />,
    code: BackhaulEligibleCode.Yespb,
  },
  {
    value: 'Not Eligible - Dunnage/Return',
    description: 'Trailer has something on it',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Drturn,
  },
  {
    value: 'Not Eligible - Internal Backhaul',
    description: 'Non-revenue customer freight',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Intbhl,
  },
  {
    value: 'Not Eligible - Short Distance',
    description: 'Less than 200 mi from domicile',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Shdist,
  },
  {
    value: 'Not Eligible - HoS Conflict',
    description: 'Not enough hours of service',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Hoscft,
  },
  {
    value: 'Not Eligible - Driver Needed',
    description: 'Next trip, worked > 8hrs, etc.',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Dnnasm,
  },
  {
    value: 'Not Eligible - Customer Preference',
    description: 'No other freight allowed',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Cupref,
  },
  {
    value: 'Not Eligible - Manager',
    description: 'Fleet manager directive',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Fmdirc,
  },
  {
    value: 'Not Eligible - Weather',
    description: 'Delays caused by weather',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Wethr,
  },
  {
    value: 'Not Eligible - Equipment',
    description: 'Special equipment',
    icon: <ProhibitIcon size={16} weight="bold" color={colors.red[600]} />,
    code: BackhaulEligibleCode.Spceqp,
  },
]

export const TRACTOR_TYPE: Map<string, string> = new Map([
  ['TA Tractor', 'TA-TRACT'],
  ['TA Conv Sleeper Trct', 'TA-SLEEP'],
  ['SA Tractor', 'SA-TRACT'],
  ['UNKNOWN', 'UNKNOWN'],
])

export const TRACTOR_TYPE_TO_COLOR: Map<string, string> = new Map([
  ['TA-TRACT', 'bg-green-50 text-green-800'],
  ['TA-SLEEP', 'bg-darkPurple-50 text-darkPurple-800'],
  ['SA-TRACT', 'bg-green-50 text-green-800'],
  ['UNKNOWN', 'bg-red-50 text-red-800'],
  ['OTHER', 'bg-red-50 text-red-800'],
])

export const TRACTOR_TYPE_TO_TOOLTIP: Map<string, string> = new Map([
  ['TA-TRACT', 'Tandem axle tractor'],
  ['TA-SLEEP', 'Tandem axle sleeper tractor'],
  ['SA-TRACT', 'Single axle tractor'],
  ['UNKNOWN', 'Unknown type'],
  ['OTHER', 'Uncommon tractor type'],
])

export const UNAVAILABLE_TRACTOR_STATUSES = [TractorStatus.Out, TractorStatus.Vac]

export const SEARCH_LIMIT = 5
export const TRACKING_SOURCE = 'load board'

export const DISPATCHED_DRIVER_REASSIGN_WARNING =
  'Since this leg has been dispatched, this action will also dispatch the leg to this driver. Do you want to proceed?'
export const AUTH_TO_DRIVE_WARNING =
  'The driver you’re trying to assign to this leg is currently not authorized to drive. Do you want to proceed?'
