import { FC, memo, useCallback } from 'react'
import { usePathname } from 'next/navigation'
import { useFlags } from 'launchdarkly-react-client-sdk'

import SignOutIcon from '~/src/components/generic/PhosphorIcons/SignOutIcon'
import GearSixIcon from '~/src/components/generic/PhosphorIcons/GearSixIcon'
import UserGearIcon from '~/src/components/generic/PhosphorIcons/UserGearIcon'

import { verifyFeatureFlag } from '~/helpers/verifyFeatureFlag'
import { isRadiusUser, isUserSysadmin } from '~/helpers/auth'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import Tab from '~/src/components/Sidebar/Tab'
import { MixpanelEvent, track } from '~/clients/mixpanelClient'
import { useTabTracking } from '~/app/(authenticated)/useTabTracking'

import { Page } from '~/app/types'

type Props = {
  pages: Readonly<Array<Page>>
  onOpenSettings?: () => void
}

const Sidebar: FC<Props> = ({ pages, onOpenSettings }) => {
  const pathname = usePathname()
  const [user, logout] = useAuthenticatedUser()

  useTabTracking(pathname.slice(1))

  const { showUserConfig } = useFlags()
  const shouldShowUserConfig = showUserConfig && isRadiusUser(user)

  const shouldShowCustomerPermissionsPage =
    verifyFeatureFlag(process.env.NEXT_PUBLIC_SHOW_PERMISSION_PAGE) &&
    isUserSysadmin(user)

  const handleOpenSettings = useCallback(() => {
    track(MixpanelEvent.UserSettingsOpen, { source: 'Sidebar' })
    onOpenSettings?.()
  }, [onOpenSettings])

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  return (
    <div className="grid-cols-auto min-w-sidebar row-span-2 flex h-screen w-sidebar flex-col justify-center bg-gray-500">
      <div className="mt-auto">
        {pages
          .filter(({ isOnSidebar }) => isOnSidebar)
          .map(
            ({ path, title, subTitle, icon: Icon }): JSX.Element => (
              <Tab
                key={path}
                isSelected={pathname.startsWith(path)}
                aria-label={title}
                path={path}
              >
                <div className="flex flex-col items-center space-y-2">
                  <Icon size={24} color="#fff" />
                  <p className="text-4xs font-normal text-white">{subTitle}</p>
                </div>
              </Tab>
            )
          )}
      </div>
      <div className="mt-auto">
        {shouldShowCustomerPermissionsPage && (
          <Tab
            key="admin-control-center"
            isSelected={
              pathname === '/admin-control-center' ||
              pathname.startsWith('/admin-control-center')
            }
            aria-label="Admin Control Center"
            path="admin-control-center"
          >
            <div className="flex flex-col items-center space-y-2">
              <UserGearIcon size={24} color="#fff" />
              <p className="text-4xs font-normal text-white">Admin</p>
            </div>
          </Tab>
        )}
        {shouldShowUserConfig && (
          <Tab key="settings" onClick={handleOpenSettings} className="mt-auto">
            <div className="flex flex-col items-center space-y-2">
              <GearSixIcon size={24} color="#fff" />
              <p className="text-4xs font-normal text-white">Settings</p>
            </div>
          </Tab>
        )}
        <Tab
          key="logout"
          onClick={handleLogout}
          className={isRadiusUser(user) ? '' : 'mt-auto'}
        >
          <div className="flex flex-col items-center space-y-2">
            <SignOutIcon size={24} color="#fff" />
            <p className="text-4xs font-normal text-white">
              {isRadiusUser(user) ? 'Log out' : 'Rydershare'}
            </p>
          </div>
        </Tab>
      </div>
    </div>
  )
}

export default memo(Sidebar)
